import React, { useState } from "react";
import { Box, FormControl, FormLabel } from "@mui/material";
import { useAuthContext } from "../../context/AuthContext";
import { verifySMSCode } from "../../services/aws/auth";
import { Logo } from "../../shared/components/Svg";

const MFASmsValidation: React.FC = () => {
  const { login } = useAuthContext();
  const [smsCode, setSmsCode] = useState<string>("");
  const codeDeliveryDestination =
    sessionStorage.getItem("codeDeliveryDestination") ?? "";

  const verifyCode = async () => {
    await verifySMSCode(smsCode);
    const AccessToken = sessionStorage.getItem("accessToken") ?? "";
    const IdToken = sessionStorage.getItem("idToken") ?? "";
    const RefreshToken = sessionStorage.getItem("refreshToken") ?? "";
    const ExpiresIn = parseInt(sessionStorage.getItem("expiresIn") ?? "0");

    if (AccessToken) {
      const tokens = {
        accessToken: AccessToken,
        idToken: IdToken,
        refreshToken: RefreshToken,
        expiresIn: ExpiresIn,
      };

      login(tokens);
      window.location.href = "/home";
    } else {
      console.error("Sign in failed.");
    }
  };

  return (
    <Box className="v-full h-full flex flex-col justify-center items-center bg-gray">
      <Logo />
      <Box className="mt-4 mb-4 text-sm font-thin">
        Please enter the 6-digit code sent to your phone number:{" "}
        <b>{codeDeliveryDestination}</b>
      </Box>

      <FormControl>
        <input
          className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
          id="smsCode"
          type="text"
          value={smsCode}
          onChange={(e) => setSmsCode(e.target.value)}
          placeholder="SMS Code"
          required
        />
      </FormControl>
      <button
        className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
        type="button"
        onClick={verifyCode}
      >
        Sign In
      </button>
    </Box>
  );
};

export default MFASmsValidation;
