import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useAuthContext } from "../../context/AuthContext";
import { MfaOption } from "../../graphql/operations";
import { setUserMFAPreference, signIn } from "../../services/aws/auth";
import Logo from "../../shared/components/Svg/Logo";
import { NavigationRoutes } from "../../utils/routes/routesUtils";
import { useCheckRouteSecurity } from "../../utils/routes/useCheckRouteSecurity";

const CustomLogin = () => {
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const { defaultPath, isLoadingDefaultPath } = useCheckRouteSecurity();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const handleSignIn = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      const response = await signIn(username, password);
      sessionStorage.setItem("password", password);
      if (!response) {
        console.error("Sign-in failed.");
        return;
      }

      if (response.type === "AuthenticationResult") {
        const { result } = response;

        if (result.AccessToken) {
          sessionStorage.setItem("accessToken", result.AccessToken ?? "");
          sessionStorage.setItem("idToken", result.IdToken ?? "");
          sessionStorage.setItem("refreshToken", result.RefreshToken ?? "");
          sessionStorage.setItem(
            "expiresIn",
            (result.ExpiresIn ?? "").toString()
          );

          const tokens = {
            accessToken: result.AccessToken,
            idToken: result.IdToken ?? "",
            refreshToken: result.RefreshToken ?? "",
            expiresIn: result.ExpiresIn ?? 0,
          };

          login(tokens);
          setShouldRedirect(true);
        } else {
          console.error("AccessToken is undefined.");
        }
      } else if (
        response.type === "Challenge" &&
        response.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        window.location.href = "/mfa-verify-auth-app-code";
      } else if (
        response.type === "Challenge" &&
        response.challengeName === "SMS_MFA"
      ) {
        sessionStorage.setItem(
          "codeDeliveryDestination",
          response.challengeParameters.CODE_DELIVERY_DESTINATION
        );
        window.location.href = "/mfa-sms-verification";
      } else if (
        response.type === "Challenge" &&
        response.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        window.location.href = "/new-password";
      } else if (
        response.type === "Challenge" &&
        response.challengeName === "MFA_SETUP"
      ) {
        await setUserMFAPreference(MfaOption.SoftwareTokenMfa, username);
        window.location.href = "/mfa-auth-app-setup";
      }
    } catch (error) {
      alert(`Sign in failed: ${error}`);
    }
  };

  useEffect(() => {
    if (shouldRedirect && !isLoadingDefaultPath) {
      navigate(defaultPath, { replace: true });
    }
  }, [defaultPath, isLoadingDefaultPath, shouldRedirect, navigate]);

  return (
    <Box className="v-full h-full flex flex-col justify-center items-center bg-gray">
      <Logo />
      <Box className="my-4 text-sm font-thin">
        Sign in with your username and password
      </Box>
      <Box className="flex flex-col justify-center w-[200px] h-[150px]">
        <input
          className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
        />

        <input
          className="mb-2 px-2 w-[200px] h-[40px] bg-gray border border border-black rounded"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button
          className="mb-2 h-[40px] bg-gray hover:bg-primary-blue hover:text-white rounded"
          onClick={handleSignIn}
        >
          Sign In
        </button>
      </Box>
    </Box>
  );
};

export default memo(CustomLogin);
