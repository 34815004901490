import { FC, memo, useState } from "react";
import { Button } from "@mui/base";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import AssetsQueryBuilderTab from "../../AssetsQueryBuilderTab/AssetsQueryBuilderTab";

const QueryBuilderFilters: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenQueryBuilderModal = () => setIsModalOpen(true);
  const handleCloseQueryBuilderModal = () => setIsModalOpen(false);

  return (
    <Box className="w-full" data-testid="filters--query-builder">
      <Box className="h-12 mb-3 flex items-center justify-between font-semibold text-[18px] leading-[26px] tracking-[-0.01em]">
        <Box>Query Builder</Box>
        <Box>
          <Button
            onClick={handleOpenQueryBuilderModal}
            data-testid="filters--query-builder--toggle-button"
          >
            <ChevronRightIcon className="cursor" />
          </Button>
        </Box>
      </Box>

      <Modal open={isModalOpen} onClose={handleCloseQueryBuilderModal}>
        <Box
          className="flex items-center justify-center w-full h-screen"
          data-testid="filters--query-builder--modal"
        >
          <Box className="flex">
            <AssetsQueryBuilderTab
              onClose={() => handleCloseQueryBuilderModal()}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default memo(QueryBuilderFilters);
