import { useEffect, useState } from "react";
import { JsonLogicResult } from "@react-awesome-query-builder/core";
import { useAppContext } from "../../../../context/AppContext";
import { MongoDBType } from "../../../../shared/components/QueryBuilder/QueryBuilder";
import { FeatureFlags } from "../../../../utils/featureFlagsConstants";
import { useFeatureFlag } from "../../../../utils/useFeatureFlag";
import { useAssetsDataContext } from "../AssetsDataContext/AssetsDataContext";

interface AssetsComplexFiltersHook {
  complexFiltersQuery: JsonLogicResult | MongoDBType;
}

export const useAssetsComplexFilters = (): AssetsComplexFiltersHook => {
  const [complexFiltersQuery, setComplexFiltersQuery] = useState<
    JsonLogicResult | MongoDBType
  >();

  const {
    currentFilter: { complexFilters, complexFiltersMongodb },
  } = useAssetsDataContext();
  const {
    state: {
      selectedOrganization: { selectedOrganization },
    },
  } = useAppContext();

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  useEffect(() => {
    let newFilters: object;

    if (fetchAssetsFromOpenSearchFeatureFlag) {
      const openSearchQueryBuilderFilters = complexFilters?.logic;

      newFilters = {
        and: [openSearchQueryBuilderFilters].filter(Boolean),
      };
    } else {
      newFilters = [complexFiltersMongodb].filter(Boolean);
    }

    if (
      newFilters &&
      JSON.stringify(newFilters) !== JSON.stringify(complexFiltersQuery)
    ) {
      setComplexFiltersQuery(newFilters);
    }
  }, [
    fetchAssetsFromOpenSearchFeatureFlag,
    selectedOrganization,
    complexFiltersMongodb,
    complexFilters,
    complexFiltersQuery,
  ]);

  return {
    complexFiltersQuery,
  };
};
