import { FC, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Chip, Divider, IconButton, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import { TextButton } from "../../../../../shared/components/Button";
import { ConfirmationDialog } from "../../../../../shared/components/ConfirmationDialog";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { useConfigurationSetsApi } from "../../../hooks/useConfigurationSetsApi";
import { ZoneCountry, ZoneData } from "../interfaces";
import CreateEditRegionDialog from "./CreateEditZoneDialog";

export interface ZoneProps {
  zoneData: ZoneData;
  showDivider?: boolean;
}

export const Zone: FC<ZoneProps> = ({ zoneData, showDivider }) => {
  const isMobile = useBreakpoint("down", "sm");
  const isDarkMode = useCurrentTheme().palette.mode === "dark";
  const queryClient = useQueryClient();
  const { dispatch } = useAppContext();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const onDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const onEditClick = () => {
    setIsEditDialogOpen(true);
  };

  const deleteRegionConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ["getConfigurationSets"] });

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "success",
        title: "Region deleted!",
        text: "Your Region Configuration is deleted successfully.",
      },
    });

    setIsDeleteDialogOpen(false);
  };

  const deleteRegionConfigurationSetOnError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "error",
        title: "Region deletion failed!",
        text: "An error occurred while deleting your Region Configuration.",
      },
    });

    setIsDeleteDialogOpen(false);
  };

  const { isLoadingDeleteConfigurationSet, deleteConfigurationSet } =
    useConfigurationSetsApi({
      deleteConfigurationSetOnSuccess: deleteRegionConfigurationSetOnSuccess,
      deleteConfigurationSetOnError: deleteRegionConfigurationSetOnError,
    });

  const handleDeleteZone = (isConfirmed: boolean) => {
    if (isLoadingDeleteConfigurationSet || !isConfirmed) {
      setIsDeleteDialogOpen(false);

      return;
    }

    deleteConfigurationSet({ entityId: zoneData.id });
  };

  const iconStyles = {
    width: isMobile ? "18px" : "24px",
    height: isMobile ? "18px" : "24px",
  };
  const iconStylesEdit = { ...iconStyles, color: "var(--brand)" };
  const iconStylesDelete = { ...iconStyles, color: "var(--error)" };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: isMobile ? "1rem" : "2rem",
        backgroundColor: isDarkMode
          ? ColorsPalette.DarkCharcoal
          : "var(--accordion-bg)",
        borderRadius: "8px",
        padding: "16px",
      }}
    >
      <Box
        data-testid="zone-section--zones"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {zoneData?.name}
        </Typography>

        {/* Edit and delete buttons */}
        <Box
          sx={{
            display: "flex",
            gap: isMobile ? "0" : "1.5rem",
          }}
        >
          {/* Edit */}
          {isMobile ? (
            <IconButton
              onClick={onEditClick}
              data-testid="edit-zone-button-mobile"
            >
              <EditOutlinedIcon sx={iconStylesEdit} />
            </IconButton>
          ) : (
            <TextButton
              text="Edit"
              icon={<EditOutlinedIcon sx={iconStyles} />}
              iconPosition="left"
              onClick={onEditClick}
              className="!text-brand !text-base !font-bold"
              data-testid="edit-zone-button"
            />
          )}

          {/* Delete */}
          {isMobile ? (
            <IconButton
              onClick={onDeleteClick}
              data-testid="delete-zone-button-mobile"
            >
              <DeleteOutlineIcon sx={iconStylesDelete} />
            </IconButton>
          ) : (
            <TextButton
              text="Delete"
              icon={<DeleteOutlineIcon sx={iconStyles} />}
              iconPosition="left"
              onClick={onDeleteClick}
              className="!text-error !text-base !font-bold"
              data-testid="delete-zone-button"
            />
          )}
        </Box>
      </Box>

      <Box data-testid="zone-section--territories">
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "8px",
          }}
        >
          Zip Matches
        </Typography>

        <Box sx={{ marginBottom: "32px" }} data-testid="zone-section--matches">
          {zoneData?.matches.map((match) => {
            const [start = "", end = ""] = `${match}`?.match(/.{1,3}/g) ?? [];

            return (
              <Chip
                key={match}
                label={
                  zoneData?.country === ZoneCountry.Canada
                    ? `${start} ${end}`.toUpperCase()
                    : match
                }
                className="!mr-2 !mb-2"
              />
            );
          })}
        </Box>
        {zoneData.country !== ZoneCountry.Canada && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              marginBottom: "8px",
            }}
          >
            Zip Ranges
          </Typography>
        )}

        <Box sx={{ marginBottom: "32px" }} data-testid="zone-section--ranges">
          {zoneData?.ranges.map((range) => {
            return (
              <Chip
                key={range.toString()}
                label={range.join(" - ")}
                className="!mr-2 !mb-2"
              />
            );
          })}
        </Box>
        {showDivider && <Divider className={isMobile ? "!my-6" : "!my-10"} />}
      </Box>

      {/* Edit Zone Dialog */}
      <CreateEditRegionDialog
        isEditDialog
        open={isEditDialogOpen}
        country={zoneData?.country}
        onClose={() => setIsEditDialogOpen(false)}
        zoneData={zoneData}
      />

      {/* Delete Zone Dialog */}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        isLoading={isLoadingDeleteConfigurationSet}
        handleConfirmationResult={handleDeleteZone}
        title="Delete Zone"
        message="Are you sure you want to delete this zone?"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
      />
    </Box>
  );
};

export default Zone;
