import { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Chip, Divider, IconButton, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AppContext";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import { TextButton } from "../../../../../shared/components/Button";
import { ConfirmationDialog } from "../../../../../shared/components/ConfirmationDialog";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { useConfigurationSetsApi } from "../../../hooks/useConfigurationSetsApi";
import { RegionData } from "../interfaces";
import { CreateEditRegionDialog } from "./CreateEditRegionDialog";

export interface RegionProps {
  regionData: RegionData;
  isDefault: boolean;
  showDivider?: boolean;
}

export const Region: React.FC<RegionProps> = ({
  regionData,
  isDefault,
  showDivider,
}) => {
  // Hooks
  const queryClient = useQueryClient();
  const { dispatch } = useAppContext();
  const isMobile = useBreakpoint("down", "sm");
  const isDarkMode = useCurrentTheme().palette.mode === "dark";

  // States
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  // Handlers
  const onCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const onCloseDeleteConfirmationDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const onEditClick = () => {
    setIsEditDialogOpen(true);
  };

  const onDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const deleteRegionConfigurationSetOnSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ["getConfigurationSets"] });

    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "success",
        title: "Region deleted!",
        text: "Your Region Configuration is deleted successfully.",
      },
    });

    onCloseDeleteConfirmationDialog();
  };

  const deleteRegionConfigurationSetOnError = () => {
    dispatch({
      type: PAGE_SNACKBAR,
      payload: {
        severity: "error",
        title: "Region deletion failed!",
        text: "An error occurred while deleting your Region Configuration.",
      },
    });

    onCloseDeleteConfirmationDialog();
  };

  const { isLoadingDeleteConfigurationSet, deleteConfigurationSet } =
    useConfigurationSetsApi({
      deleteConfigurationSetOnSuccess: deleteRegionConfigurationSetOnSuccess,
      deleteConfigurationSetOnError: deleteRegionConfigurationSetOnError,
    });

  const handleDeleteRegion = (isConfirmed: boolean) => {
    if (isLoadingDeleteConfigurationSet || !isConfirmed) {
      onCloseDeleteConfirmationDialog();

      return;
    }

    deleteConfigurationSet({ entityId: regionData.id });
  };

  const iconStyles = {
    width: isMobile ? "18px" : "24px",
    height: isMobile ? "18px" : "24px",
  };
  const iconStylesEdit = { ...iconStyles, color: "var(--brand)" };
  const iconStylesDelete = { ...iconStyles, color: "var(--error)" };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: isMobile ? "1rem" : "2rem",
        backgroundColor: isDarkMode
          ? ColorsPalette.DarkCharcoal
          : "var(--accordion-bg)",
        borderRadius: "8px",
        padding: "16px",
      }}
    >
      <Box
        data-testid="region-section--regions"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {regionData.name}
        </Typography>

        {/* Edit and delete buttons */}
        {!isDefault && (
          <Box
            sx={{
              display: "flex",
              gap: isMobile ? "0" : "1.5rem",
            }}
          >
            {/* Edit */}
            {isMobile ? (
              <IconButton
                onClick={onEditClick}
                data-testid="edit-region-button-mobile"
              >
                <EditOutlinedIcon sx={iconStylesEdit} />
              </IconButton>
            ) : (
              <TextButton
                text="Edit"
                icon={<EditOutlinedIcon sx={iconStyles} />}
                iconPosition="left"
                onClick={onEditClick}
                className="!text-brand !text-base !font-bold"
                data-testid="edit-region-button"
              />
            )}

            {/* Delete */}
            {isMobile ? (
              <IconButton
                onClick={onDeleteClick}
                data-testid="delete-region-button-mobile"
              >
                <DeleteOutlineIcon sx={iconStylesDelete} />
              </IconButton>
            ) : (
              <TextButton
                text="Delete"
                icon={<DeleteOutlineIcon sx={iconStyles} />}
                iconPosition="left"
                onClick={onDeleteClick}
                className="!text-error !text-base !font-bold"
                data-testid="delete-region-button"
              />
            )}
          </Box>
        )}
      </Box>

      {/* Territories chips */}
      <Box data-testid="region-section--territories">
        {regionData.territories.map((territory) => (
          <Chip key={territory} label={territory} className="!mr-2 !mb-2" />
        ))}
        {showDivider && <Divider className={isMobile ? "!my-6" : "!my-10"} />}
      </Box>

      {/* Edit Region Dialog */}
      <CreateEditRegionDialog
        isEditDialog
        open={isEditDialogOpen}
        country={regionData.country}
        onClose={onCloseEditDialog}
        regionData={regionData}
      />

      {/* Delete Region Dialog */}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        isLoading={isLoadingDeleteConfigurationSet}
        handleConfirmationResult={handleDeleteRegion}
        title="Delete Region"
        message="Are you sure you want to delete this region?"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
      />
    </Box>
  );
};
