import { FC, useState, useMemo } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import { ColorsPalette } from "../../../../../design-system/colors-palette";
import { TextButton } from "../../../../../shared/components/Button";
import { useCurrentTheme } from "../../../../../shared/hooks/theme/useCurrentTheme";
import useBreakpoint from "../../../../../shared/hooks/useBreakpoint";
import { useGetZonesData } from "../hooks/useGetZonesData";
import { countryToDisplayName, ZoneCountry } from "../interfaces";
import CreateEditZoneDialog from "./CreateEditZoneDialog";
import Zone from "./Zone";

export interface ZonesSectionProps {
  country: ZoneCountry;
}

export const ZonesSection: FC<ZonesSectionProps> = ({ country }) => {
  // Hooks
  const isMobile = useBreakpoint("down", "sm");
  const isDarkMode = useCurrentTheme().palette.mode === "dark";

  // States
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

  // Variables
  const iconStyles = { width: "24px", height: "24px", color: "var(--brand)" };

  const { zones, isLoading } = useGetZonesData();
  const zoneGroupData = useMemo(() => zones?.[country], [zones, country]);

  return (
    <Paper
      data-testid="zones-section"
      className="!rounded-lg !border-solid !border-sub-header-border"
      sx={{
        boxShadow: "0px 2px 4px 0px var(--box-shadow)",
        border: "1px solid #DFDFDF",
        overflow: "hidden",
      }}
    >
      <Accordion>
        <AccordionSummary
          sx={{
            backgroundColor: isDarkMode
              ? ColorsPalette.FlatBlack
              : "var(--card-bg)",
            margin: 0,
            padding: "0 1rem",
          }}
        >
          {countryToDisplayName[country]}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "0 0 1rem 0",
            backgroundColor: isDarkMode
              ? ColorsPalette.FlatBlack
              : "var(--card-bg)",
          }}
        >
          <Box
            data-testid="zones-section--buttons"
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingX: "1rem",
              gap: "2rem",
            }}
          >
            <TextButton
              text="Add New Zone"
              icon={<AddIcon sx={iconStyles} />}
              iconPosition="left"
              onClick={() => setIsCreateDialogOpen(true)}
              className="!text-brand !text-base !font-bold"
              data-testid="add-new-zone-button"
            />
          </Box>

          <Box
            sx={{
              backgroundColor: isDarkMode
                ? ColorsPalette.FlatBlack
                : "var(--card-bg)",
              padding: "0 1rem",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: isDarkMode
                  ? ColorsPalette.FlatBlack
                  : "var(--card-bg)",
                gap: "1rem",
              }}
            >
              {isLoading ? (
                <Box
                  className="flex h-full w-full items-center justify-center"
                  data-testid={`zone-section-loader-${country}`}
                >
                  <CircularProgress />
                </Box>
              ) : (
                zoneGroupData.data.map((zone, index) => (
                  <Zone
                    key={zone.id}
                    zoneData={zone}
                    showDivider={index !== zoneGroupData.data.length - 1}
                  />
                ))
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Create Zone Dialog */}
      <CreateEditZoneDialog
        open={isCreateDialogOpen}
        country={country}
        onClose={() => setIsCreateDialogOpen(false)}
      />
    </Paper>
  );
};
