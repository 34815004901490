import { FC, useCallback, useState } from "react";
import { Button as BaseButton } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Paper, Typography } from "@mui/material";
import type { JsonLogicResult } from "@react-awesome-query-builder/ui";
import ActionButton from "../../../../shared/components/Button/ActionButton";
import TextButton from "../../../../shared/components/Button/TextButton/TextButton";
import QueryBuilder, {
  CustomFormatResponseType,
} from "../../../../shared/components/QueryBuilder";
import { assetQueryBuilderFields } from "../../../../shared/components/QueryBuilder/config";
import { FeatureFlags } from "../../../../utils/featureFlagsConstants";
import { useFeatureFlag } from "../../../../utils/useFeatureFlag";
import { useAssetsDataContext } from "../AssetsDataContext";

export interface QueryBuilderDialogProps {
  onClose: () => void;
}
const AssetsQueryBuilderTab: FC<QueryBuilderDialogProps> = ({ onClose }) => {
  const [complexFiltersState, setComplexFiltersState] = useState<
    JsonLogicResult | undefined
  >();
  const [complexFiltersMongodbState, setComplexFiltersMongodbState] = useState<
    CustomFormatResponseType | undefined
  >();
  const [shouldReset, setShouldReset] = useState(false);

  const { currentFilter, onChangeFilters } = useAssetsDataContext();

  const resetQueryBuilderHandler = useCallback(() => {
    setShouldReset(true);
    onChangeFilters({
      complexFilters: undefined,
      complexFiltersMongodb: undefined,
    });
  }, [onChangeFilters]);

  const onReset = useCallback(() => {
    setShouldReset(false);
  }, []);

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  return (
    <Paper
      elevation={0}
      className="md:w-[880px] w-full"
      data-testid="asset-query-builder"
    >
      <Box className="p-6 max-h-[85vh] overflow-auto">
        <Box>
          <Box className="flex justify-between !pb-4">
            <Typography
              className="!text-lg !font-bold --text"
              variant="h6"
              component="div"
            >
              Custom Query
            </Typography>
            <BaseButton
              onClick={onClose}
              data-testid="assets-query-builder--close-button"
            >
              <CloseIcon />
            </BaseButton>
          </Box>

          <Box className="flex justify-end !pb-4">
            <Button
              data-testid="reset-query-view-btn"
              className="global-text-btn global-text-btn--medium global-text-btn__theme--blue !font-bold !capitalize !text-brand"
              onClick={resetQueryBuilderHandler}
            >
              Reset query view
            </Button>
          </Box>
        </Box>
        <div className="mb-4">
          <QueryBuilder
            value={currentFilter.complexFilters?.logic}
            format="mongodb"
            //@ts-ignore
            configFields={assetQueryBuilderFields.asset.subfields}
            onQueryChange={(response, customFormatResponse) => {
              setComplexFiltersState(response);

              if (!fetchAssetsFromOpenSearchFeatureFlag) {
                setComplexFiltersMongodbState(customFormatResponse);
              }
            }}
            shouldReset={shouldReset}
            onReset={onReset}
          />
        </div>
        <div className="flex justify-end pr-2">
          <TextButton
            className="block text-primary border-primary text-typography"
            text="Cancel"
            theme="blue"
            size="medium"
            onClick={() => {
              onClose();
            }}
          />
          <ActionButton
            testId="apply-query-view-btn"
            className="block text-primary border-primary text-typography"
            onClick={() => {
              onChangeFilters({
                complexFilters: complexFiltersState,
                complexFiltersMongodb: complexFiltersMongodbState,
              });
              onClose();
            }}
          >
            Save
          </ActionButton>
        </div>
      </Box>
    </Paper>
  );
};

export default AssetsQueryBuilderTab;
