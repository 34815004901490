import { useState, useCallback, FC, useEffect, useMemo } from "react";
import {
  Query,
  Builder,
  Utils as QbUtils,
} from "@react-awesome-query-builder/mui";
import "@react-awesome-query-builder/mui/css/styles.css";
import type {
  JsonGroup,
  ImmutableTree,
  BuilderProps,
  Fields,
  JsonLogicResult,
} from "@react-awesome-query-builder/ui";
import { isEqual, throttle } from "lodash";
import { useAssetsDataContext } from "../../../views/AssetsView/shared/AssetsDataContext";
import { useQueryBuilderConfig } from "./useQueryBuilderConfig";

const queryValue: JsonGroup = { id: QbUtils.uuid(), type: "group" };

// If more types are required in the future, list them as union like: "mongodb | sql", etc
export type Format = "mongodb" | undefined;

export type JSONFormatType = {
  logic: Object | undefined;
  data: Object | undefined;
  errors: string[] | undefined;
};

export type MongoDBType = object | undefined; // TODO: Remove this later, we want to transmit JsonLogic to the BE

export type CustomFormatResponseType = MongoDBType;

export interface QueryBuilderProps {
  configFields: Fields;
  format?: Format;
  onQueryChange: (
    response: JsonLogicResult,
    responseInCustomFormat?: CustomFormatResponseType
  ) => void;
  value?: Object;
  shouldReset?: boolean;
  onReset?: () => void;
}

const QueryBuilder: FC<QueryBuilderProps> = ({
  configFields,
  format = "mongodb",
  onQueryChange,
  value,
  shouldReset,
  onReset,
}) => {
  const config = useQueryBuilderConfig(configFields);
  const { shouldUpdateQueryBuilder, setShouldUpdateQueryBuilder } =
    useAssetsDataContext();

  // Config was removed from state in case of any issues please return it
  const [tree, setTree] = useState(
    QbUtils.loadFromJsonLogic(value, config) ?? QbUtils.loadTree(queryValue)
  );

  useEffect(() => {
    // used for reset a queryBuilder
    if (shouldReset) {
      setTree(QbUtils.checkTree(QbUtils.loadTree(queryValue), config));
      if (onReset) onReset();
    }
  }, [shouldReset, onReset, config]);

  const updateState = throttle((updatedTree: ImmutableTree) => {
    setTree(updatedTree);
  }, 500);

  useEffect(() => {
    const newTree =
      QbUtils.loadFromJsonLogic(value, config) ?? QbUtils.loadTree(queryValue);
    if (!isEqual(newTree, tree) && shouldUpdateQueryBuilder) {
      updateState(newTree);
      setShouldUpdateQueryBuilder(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChange = useCallback(
    (immutableTree: ImmutableTree) => {
      // Tip: for better performance you can apply `throttle` - see `examples/demo`
      updateState(immutableTree);

      // List formats different than JSONLogic
      const formats = {
        mongodb: QbUtils.mongodbFormat,
      };

      const response = QbUtils.jsonLogicFormat(immutableTree, config);
      const responseInCustomFormat = formats[format](immutableTree, config);

      onQueryChange(response, responseInCustomFormat);
    },
    [onQueryChange, format, config, updateState]
  );

  // add title to the conjunctions buttons
  const buttonsContainers = document.querySelectorAll(".group--conjunctions");
  if (buttonsContainers.length) {
    buttonsContainers.forEach((buttonsContainer) => {
      const buttons = buttonsContainer.querySelectorAll("button");
      buttons.forEach((button) => {
        // this means that the button has selected state
        const condition =
          button.classList.contains("MuiButton-containedSecondary") ||
          button.classList.contains("MuiButton-containedPrimary") ||
          button.classList.contains("MuiButton-containedError");
        if (condition) {
          button.setAttribute("title", "It's enabled");
        } else {
          button.setAttribute("title", "It's disabled");
        }
      });
    });
  }

  const renderBuilder = useCallback(
    (props: BuilderProps) => (
      <div data-testid="query-builder">
        <Builder {...props} />
      </div>
    ),
    []
  );

  return (
    <div className="query-builder-container">
      <Query
        {...config}
        value={tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
    </div>
  );
};

export default QueryBuilder;
