import { Box } from "@mui/material";
import { ZonesSection } from "./components/ZonesSection";
import { ZoneCountry } from "./interfaces";

const Zones = () => {
  return (
    <Box
      data-testid="zones"
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "4rem",
        gap: "1rem",
      }}
    >
      <ZonesSection country={ZoneCountry.UnitedStates} />
      <ZonesSection country={ZoneCountry.Canada} />
      <ZonesSection country={ZoneCountry.Mexico} />
    </Box>
  );
};

Zones.displayName = "Zones";
export default Zones;
