import React, { memo, useState, useMemo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useQueryClient } from "@tanstack/react-query";
import { PAGE_SNACKBAR } from "../../../../constants";
import { BatchTitles } from "../../../../constants/batches";
import {
  ORGANIZATIONS_LOAD_FAILED_PAYLOAD,
  ORGANIZATION_LOAD_FAILED_PAYLOAD,
} from "../../../../constants/organizations";
import { useAppContext } from "../../../../context/AppContext";
import { useAuthContext } from "../../../../context/AuthContext";
import { FormModes } from "../../../../enums/formModes";
import { useFindOrgsQuery, OrgData } from "../../../../graphql/operations";
import ActionButton from "../../../../shared/components/Button/ActionButton";
import { WithPermissions } from "../../../../shared/components/WithPermissions";
import { useAvailableOrgs } from "../../../../shared/hooks/useAvailableOrgs";
import { useGlobalOrganizationFilter } from "../../../../shared/hooks/useGlobalOrganizationFilter";
import { useUserData } from "../../../../shared/hooks/useUserData";
import {
  BATCH_FORM_FIELDS,
  BatchFormFieldsNames,
  mapOrgs,
} from "../../../BatchesView/BatchManagementUtils";
import { UploadCustomerOrgsDialog } from "../../../DeviceManagementView/UploadCustomerOrgsDialog/UploadCustomerOrgsDialog";
import CreateEditOrg from "./CreateOrgForm/CreateOrgForm";
import OrgManagementTable from "./OrgManagementTable";

const OrgManagement: React.FC = () => {
  const { dispatch } = useAppContext();
  const queryClient = useQueryClient();
  const [drawerState, setDrawerState] = useState(false);
  const [initialSearch, setInitialSearch] = useState("");
  const gridApiRef = useGridApiRef();
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const userData = useUserData();
  const availableOrgs = useAvailableOrgs();

  const { userRolePermissions, refreshAuthTokens$ } = useAuthContext();
  const createOrgHandler = () => setDrawerState(!drawerState);
  const [formMode, setFormMode] = useState<FormModes>(FormModes.create);
  const [organizationToEdit, setOrganizationToEdit] = useState<OrgData | null>(
    null
  );
  const globalOrgFilter = useGlobalOrganizationFilter();
  const [isUploadOrganizationsDialogOpen, setIsUploadOrganizationsDialogOpen] =
    useState<boolean>(false);

  const {
    data: organizationsGQLData,
    isSuccess: isOrganizationsDataSuccess,
    isLoading: isOrganizationsDataLoading,
    refetch: refetchOrganizationsData,
    isRefetching: IsOrganizationsDataRefetching,
  } = useFindOrgsQuery(
    { input: { id: globalOrgFilter.value } },
    {
      onSettled: (_, error) => {
        if (error) {
          dispatch({
            type: PAGE_SNACKBAR,
            payload: ORGANIZATIONS_LOAD_FAILED_PAYLOAD,
          });
        }
      },
    }
  );

  useEffect(() => {
    if (search && isOrganizationsDataSuccess) {
      setInitialSearch(search);
    }
  }, [search, isOrganizationsDataSuccess]);

  const organizationsList = useMemo<OrgData[]>(
    () =>
      ((organizationsGQLData?.findOrgs as OrgData[]) || []).sort((org1, org2) =>
        org1.name < org2.name ? -1 : 1
      ) || [],
    [organizationsGQLData?.findOrgs]
  );

  const allOrganizationsList = useMemo<OrgData[]>(
    () => availableOrgs ?? [],
    [availableOrgs]
  );
  const uploadOrganizationsHandler = () => {
    setIsUploadOrganizationsDialogOpen(true);
  };

  const onUploadOrganizationsDialogClose = () => {
    setIsUploadOrganizationsDialogOpen(false);
  };

  const orgs = mapOrgs(organizationsGQLData?.findOrgs ?? []);
  BATCH_FORM_FIELDS[BatchFormFieldsNames.ParentOrganization].values = orgs;

  useEffect(() => {
    if (!drawerState) {
      setFormMode(FormModes.create);
      setOrganizationToEdit(null);
    }
  }, [drawerState]);

  const handleTableCellClick = (e: any) => {
    queryClient.invalidateQueries(
      { queryKey: ["findOrg"] },
      { cancelRefetch: true }
    );
    queryClient.invalidateQueries(
      { queryKey: ["getOrgFileUploadUrl"] },
      { cancelRefetch: true }
    );
    const _id = e?.id;
    const organizationSelected = (organizationsList || []).find(
      (organization: OrgData) => organization._id === _id
    );
    setOrganizationToEdit(organizationSelected || null);

    if (organizationSelected) {
      setFormMode(FormModes.edit);
      setDrawerState(true);
    }
  };

  const handleRefetchRequest = () => {
    refreshAuthTokens$((error: any) => {
      if (error) {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: ORGANIZATION_LOAD_FAILED_PAYLOAD,
        });
        return;
      }
      queryClient.invalidateQueries({ queryKey: ["findOrgs"] });
      refetchOrganizationsData();
    });
  };

  return (
    <Box
      title=""
      className="bg-background pt-2 h-[90%]"
      data-testid="page-org-management"
    >
      <Box display="flex" justifyContent="flex-end">
        <WithPermissions accessScope="org.upload">
          <Button
            className={"global-btn !py-1 !text-sm !text-primary"}
            variant="text"
            type="button"
            onClick={uploadOrganizationsHandler}
            sx={{
              display: "block",
              margin: "10px 0",
              border: "solid",
              borderWidth: "2px",
              fontWeight: "bold",
            }}
          >
            Upload
          </Button>
        </WithPermissions>
        <WithPermissions accessScope="org.create">
          <ActionButton
            data-testid="new-organization-button"
            onClick={createOrgHandler}
            sx={{ display: "block", margin: "10px 0", marginLeft: "1rem" }}
          >
            New Organization
          </ActionButton>
        </WithPermissions>
      </Box>

      {(userRolePermissions?.org?.create || userRolePermissions?.org?.edit) &&
        drawerState && (
          <CreateEditOrg
            open={drawerState}
            setOpen={setDrawerState}
            onOrgCreate={handleRefetchRequest}
            onOrgUpdate={handleRefetchRequest}
            organizations={allOrganizationsList}
            formMode={formMode}
            organizationToEdit={organizationToEdit}
          />
        )}
      {!!userRolePermissions?.org?.view && (
        <OrgManagementTable
          data-testid="component-org-management-table"
          organizations={organizationsList}
          apiRef={gridApiRef}
          initialSearch={initialSearch}
          isSuccess={isOrganizationsDataSuccess}
          isLoading={
            isOrganizationsDataLoading || IsOrganizationsDataRefetching
          }
          onRowClick={handleTableCellClick}
          isDataRefetching={IsOrganizationsDataRefetching}
        />
      )}
      {isUploadOrganizationsDialogOpen && (
        <UploadCustomerOrgsDialog
          title={BatchTitles.CreateUpdateOrganizations}
          customerOrg={userData?.customerOrg?.name}
          dialogFields={BATCH_FORM_FIELDS}
          isOpen={isUploadOrganizationsDialogOpen}
          onClose={onUploadOrganizationsDialogClose}
          onUpload={() => queryClient.invalidateQueries(["getBatchHistory"])}
        />
      )}
    </Box>
  );
};

// we should add displayName property manually to memoized components because
// there is no other way to access the name of the component if we need it
OrgManagement.displayName = "Organizations";
export default memo(OrgManagement);
