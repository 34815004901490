import { useEffect, useState } from "react";
import { merge } from "lodash";
import { PAGE_SNACKBAR } from "../../../../constants";
import { useAppContext } from "../../../../context/AppContext";
import {
  TableFiltersInput,
  useGetAssetsForGalleryOsQuery,
  useGetAssetsForGalleryQuery,
} from "../../../../graphql/operations";
import { useUserData } from "../../../../shared/hooks/useUserData";
import { FeatureFlags } from "../../../../utils/featureFlagsConstants";
import { useFeatureFlag } from "../../../../utils/useFeatureFlag";
import { mapAssetFilters } from "../../TableView/components/AssetsTable/utils";
import {
  assetsListInputParams,
  defaultAssetsFilter,
} from "../../helpers/helpers";
import { useAssetsDataContext } from "../../shared/AssetsDataContext";
import { onGetAssetsSuccess } from "../../shared/AssetsDataContext/helpers";
import { useAssetsComplexFilters } from "../../shared/hooks/useAssetsComplexFilters";
import { useFiltersParam } from "../../shared/hooks/useFiltersParam";

export const useGetAssetsGallery = () => {
  const {
    assetsPagination,
    lastAssetDateAtPage,
    searchParams,
    currentFilter: { complexFilters, complexFiltersMongodb },
    debouncedMapViewCoordinatesFromUrl,
    assetsSearchInput,
    filters,
    currentFilter,
  } = useAssetsDataContext();

  const {
    state: {
      assets,
      selectedOrganization: { selectedOrganization },
    },
    dispatch,
  } = useAppContext();
  const userData = useUserData();

  const [isAssetDataTableLoading, setIsAssetDataTableLoading] =
    useState<boolean>(false);
  const [isAssetDataTableRefetching, setIsAssetDataTableRefetching] =
    useState<boolean>(false);
  const [tableFilters, setTableFilters] = useState<{
    filterList: TableFiltersInput | undefined;
  }>();

  // TODO: Cleanup with PRJIND-9218
  const fetchAssetsFromOpenSearchFeatureFlag = useFeatureFlag(
    FeatureFlags.Connect1FetchAssetsFromOpenSearch
  );

  assetsListInputParams.skip = 0;

  const filtersParam = useFiltersParam(lastAssetDateAtPage, filters);
  const filtersObj = merge({}, defaultAssetsFilter, filtersParam);

  useEffect(() => {
    const filters = mapAssetFilters(
      currentFilter,
      userData,
      selectedOrganization?.value,
      fetchAssetsFromOpenSearchFeatureFlag
    );
    if (JSON.stringify(filters) !== JSON.stringify(tableFilters?.filterList)) {
      setTableFilters({ filterList: filters });
    }
  }, [
    selectedOrganization,
    currentFilter,
    assetsSearchInput,
    userData,
    fetchAssetsFromOpenSearchFeatureFlag,
    tableFilters?.filterList,
  ]);

  const zoomLevelUrlParam = searchParams.get("zoomLevel");
  const zoom = zoomLevelUrlParam ? { zoom: parseFloat(zoomLevelUrlParam) } : {};
  const viewport = debouncedMapViewCoordinatesFromUrl?.length
    ? { viewport: debouncedMapViewCoordinatesFromUrl }
    : {};

  const filterInput = fetchAssetsFromOpenSearchFeatureFlag
    ? tableFilters
    : filtersObj;
  const { complexFiltersQuery } = useAssetsComplexFilters();

  const queryParams = (isEnabled: boolean) => [
    {
      input: {
        ...assetsListInputParams,
        skip: assetsPagination,
        ...filterInput,
        complexFilters: JSON.stringify(complexFiltersQuery),
        search: assetsSearchInput,
        ...viewport,
        ...zoom,
      },
    },
    {
      onSuccess: () => {
        onGetAssetsSuccess(assets.isOnClickDisabled, dispatch);
      },
      onError: () => {
        dispatch({
          type: PAGE_SNACKBAR,
          payload: {
            title: "Failed to load assets!",
            text: "Something Went Wrong.",
            severity: "error",
          },
        });
      },
      keepPreviousData: true,
      enabled: isEnabled && Boolean(complexFiltersQuery),
    },
  ];

  const {
    data: assetDataTableDocumentDb,
    isLoading: isAssetDataTableLoadingDocDb,
    isRefetching: isAssetDataTableRefetchingDocDb,
  } = useGetAssetsForGalleryQuery(
    ...(queryParams(!fetchAssetsFromOpenSearchFeatureFlag) as any) // fix type later
  );

  const {
    data: assetDataTableOS,
    isLoading: isAssetDataTableLoadingOS,
    isRefetching: isAssetDataTableRefetchingOS,
  } = useGetAssetsForGalleryOsQuery(
    ...(queryParams(fetchAssetsFromOpenSearchFeatureFlag) as any) // fix type later
  );

  useEffect(() => {
    if (fetchAssetsFromOpenSearchFeatureFlag) {
      setIsAssetDataTableLoading(isAssetDataTableLoadingOS);
      setIsAssetDataTableRefetching(isAssetDataTableRefetchingOS);
    } else {
      setIsAssetDataTableLoading(isAssetDataTableLoadingDocDb);
      setIsAssetDataTableRefetching(isAssetDataTableRefetchingDocDb);
    }
  }, [
    fetchAssetsFromOpenSearchFeatureFlag,
    isAssetDataTableLoadingDocDb,
    isAssetDataTableLoadingOS,
    isAssetDataTableRefetchingDocDb,
    isAssetDataTableRefetchingOS,
  ]);

  const returnQueryData = fetchAssetsFromOpenSearchFeatureFlag
    ? assetDataTableOS?.getAssetsOS
    : assetDataTableDocumentDb?.getAssets;

  return {
    data: returnQueryData,
    isLoading: isAssetDataTableLoading,
    isRefetching: isAssetDataTableRefetching,
  };
};
